const dictionary = {};

const languages = [
    {
        code: "es-MX",
        label: "Español"
    },
    {
        code: "en-US",
        label: "Inglés"
    }
]

const makeDictionary = function () {
    languages.forEach(language =>
        dictionary[language.code] = language
    )
}

export function getLanguageById(id) {
    if (Object.keys(dictionary).length === 0) {
        makeDictionary()
    }
    return dictionary[id] || {}
}

export default languages
